import {
  createPlugin,
  createRoutableExtension,
  createRouteRef,
} from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'sre',
});

export const srePlugin = createPlugin({
  id: 'sre',
  routes: {
    entityContent: rootRouteRef,
  },
});

export const SrePage: () => JSX.Element = srePlugin.provide(
  createRoutableExtension({
    name: 'SrePage',
    component: () =>
      import('./components/OperationalMaturityComponent').then(
        m => m.OperationalMaturityComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
